import React from 'react'
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import LayoutStatic from '../../../components/LayoutStatic';

const style = {
  card: {
    width: '18rem',
    margin: 'auto',
    marginTop: '20px',
  },
  button: {
    marginTop: '10px',
    width: '100%'
  }
}

const IndexPage = () => {
  return (
    <LayoutStatic>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6}>
          <div className="card w-100 mt-1">
            <img src="/img/08pic.jpg" className="card-img-top bootstrap-card-img-top" alt="..." />
            <div className="card-body">
              <h5 className="card-title">เด็กอายุ 8 ปี</h5>
              <p className="card-text">ลูกของเราพัฒนาการสมวัยหรือไม่? ร่วมประเมินกับผู้เชี่ยวชาญเฉพาะทาง นักกิจกรรมบำบัด ฟรี!!</p>
              <Link to="/questionnaire?questionnaireId=10" className="btn btn-primary" style={style.button}>ประเมินพัฒนาการกล้ามเนื้อมัดเล็กและการเขียน</Link>
              {/* <Link to="/questionnaire?questionnaireId=11" className="btn btn-primary" style={style.button}>ประเมินพัฒนาการทางภาษา</Link>
          <Link to="/questionnaire?questionnaireId=18" className="btn btn-primary" style={style.button}>ประเมินพัฒนาการกล้ามเนื้อมัดใหญ่</Link>
          <Link to="/questionnaire?questionnaireId=24" className="btn btn-primary" style={style.button}>ประเมินพัฒนาการทางสังคม และการช่วยเหลือตนเอง</Link> */}

              <Link to="/questionnaire?questionnaireId=30" className="btn btn-primary" style={style.button}>ประเมินคัดกรองโรคสมาธิสั้น</Link>
              <Link to="/menu/child/Sensory" className="btn btn-primary" style={style.button}>ระบบประสาทรับความรู้สึก</Link>
              <Link to="/questionnaire?questionnaireId=55" className="btn btn-primary" style={style.button}>ประเมินความฉลาดทางอารมณ์</Link>

            </div>
          </div>
        </Grid>
      </Grid>
    </LayoutStatic>
  );
}

export default IndexPage

